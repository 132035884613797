<template>
  <div v-if="vehicle" class="sc-rtp-window">
    <!-- 头部 -->
    <div class="Header">
      <!-- {{ vehicle.P }} -->
      <!-- <span class="stateSpan">{{getStateLabel}}</span> -->
      <div  class="Header-left">
        <div v-for="item,index in (vehicle.terminalInfos || [])" :key="index" :class="['Header-state', 'Header-state' + (item.isOnline ? '1' : '')]">{{ terminalName[item.deviceType] }}-{{ item.isOnline ? '在线' : '离线' }}</div>
      </div>
      <!-- <div v-else class="Header-left">
        <div v-for="item,index in (vehicle.terminalInfos || [])" :key="index" :class="['Header-state', 'Header-state' + (item.isOnline ? '1' : '')]">AEB--{{ item.isOnline ? '在线' : '离线' }}</div>
      </div> -->
      <div class="Header-alarm">
        <img src="../../assets/images/realTimePositioning/ss-alarm.png" alt="" srcset="">
        <marquee style="font-size: 12px;color: #FF5B40;">{{ vehicle.B || vehicle.BD }}</marquee>
      </div>
      <div class="Header-right">
        <span class="closeIcon" @click.stop="$emit('close')">
          <ElIcon name="el-icon-close" title="关闭"></ElIcon>
        </span>
      </div>
    </div>
    <!-- 内容 -->
    <div class="ContentOuter">
      <div class="ContentOuter-car">
          <Iconfont :size="75" :name="vehicle.imageUrl"></Iconfont>
          <div class="ContentOuter-car-content">
            <div class="ContentOuter-car-content-p">
              <span>{{ vehicle.P }}</span>
              <div  v-if="vehicle.isCommercialVehicles" :class="['Header-state', 'Header-state' + ( vehicle.Z)]">{{ getStateLabel }}</div>
              <div  v-else :class="['Header-state', 'Header-state' + vehicle.Z]">{{getStateLabel}}</div>
            </div>
            <div>{{ vehicle.machineryProductTypeStr }} {{ vehicle.frameNo }}</div>
            <div>车组：{{ vehicle.groupName }}</div>
          </div>
      </div>
      <div class="ContentOuter-card">
        <div class="ContentOuter-card-statistics" v-if="vehicle.isCommercialVehicles">
          <div>
            <div class="label">今日工时</div>
            <div class="v">
              <span>{{ vehicle.inWorkingTime ? getDwellTime(vehicle.inWorkingTime, 0) : '--' }}</span>小时<span>{{ vehicle.inWorkingTime ? getDwellTime(vehicle.inWorkingTime, 1) : '--' }}</span>分
            </div>
          </div>
          <div class="bebebe">
            <div class="label">持续{{ getStateLabel }}</div>
            <div class="v">
              <div v-if="vehicle.Dm && getDwellTime6(vehicle.Dm * 1000, 0)"><span >{{ vehicle.Dm ? getDwellTime6(vehicle.Dm * 1000, 0) : '--' }}</span>天</div>
              <div><span >{{ vehicle.Dm ? getDwellTime6(vehicle.Dm * 1000, 1) : '--' }}</span>时</div>
              <div><span >{{ vehicle.Dm ? getDwellTime6(vehicle.Dm * 1000, 2) : '--' }}</span>分</div>
              <div v-if="vehicle.Dm && !getDwellTime6(vehicle.Dm * 1000, 0)"><span >{{ vehicle.Dm ? getDwellTime6(vehicle.Dm * 1000, 3) : '--' }}</span>秒</div>
              <!-- <span >{{ vehicle.Dm ? getDwellTime6(vehicle.Dm * 1000, 0) : '--' }}</span>小时<span>{{ vehicle.Dm ? getDwellTime(vehicle.Dm, 1) : '--' }}</span>分 -->
            </div>
          </div>
          <div class="bebebe">
            <div class="label">今日刹车</div>
            <div class="v">
              <span>{{ vehicle.shaCheAlarmCount }}</span>次
            </div>
          </div>
          <div class="bebebe">
            <div class="label">其他告警</div>
            <div class="v">
              <span>{{ vehicle.alarmCommercialVehiclesCount }}</span>次
            </div>
          </div>
        </div>
        <div class="ContentOuter-card-statistics" v-else>
          <div>
            <div class="label">今日工时</div>
            <div class="v">
              <span>{{ vehicle.inWorkingTime ? getDwellTime(vehicle.inWorkingTime, 0) : '--' }}</span>小时<span>{{ vehicle.inWorkingTime ? getDwellTime(vehicle.inWorkingTime, 1) : '--' }}</span>分
            </div>
          </div>
          <div class="bebebe">
            <div class="label">持续{{ getStateLabel }}</div>
            <div class="v">
              <div v-if="vehicle.Dm && getDwellTime6(vehicle.Dm * 1000, 0)"><span >{{ vehicle.Dm ? getDwellTime6(vehicle.Dm * 1000, 0) : '--' }}</span>天</div>
              <div><span >{{ vehicle.Dm ? getDwellTime6(vehicle.Dm * 1000, 1) : '--' }}</span>时</div>
              <div><span >{{ vehicle.Dm ? getDwellTime6(vehicle.Dm * 1000, 2) : '--' }}</span>分</div>
              <div v-if="vehicle.Dm && !getDwellTime6(vehicle.Dm * 1000, 0)"><span >{{ vehicle.Dm ? getDwellTime6(vehicle.Dm * 1000, 3) : '--' }}</span>秒</div>
              <!-- <span>{{ vehicle.Dm ? getDwellTime(vehicle.Dm, 0) : '--' }}</span>小时<span>{{ vehicle.Dm ? getDwellTime(vehicle.Dm, 1) : '--' }}</span>分 -->
            </div>
          </div>
          <div class="bebebe">
            <div class="label">今日告警</div>
            <div class="v">
              <span>{{ vehicle.alarmCommercialVehiclesCount }}</span>次
            </div>
          </div>
        </div>
        <div class="ContentOuter-card-condition">
          <div>
              <img src="../../assets/images/realTimePositioning/zt-sd.png" class="tab-icon" alt="" srcset="">
            <span>当前速度：</span>
            <div>{{ vehicle.S }}km/h</div>
          </div>
          <div v-if="!vehicle.isCommercialVehicles">
              <img src="../../assets/images/realTimePositioning/zt-lc.png" class="tab-icon" alt="" srcset="">
            <span>今日里程：</span>
            <div>{{ vehicle.L }}km</div>
          </div>
          <div v-if="!vehicle.isCommercialVehicles">
              <img src="../../assets/images/realTimePositioning/zt-dy.png" class="tab-icon" alt="" srcset="">
            <span>电压：</span>
            <div>{{ vehicle.voltage || 0 }}V</div>
          </div>
          <div v-if="!vehicle.isCommercialVehicles">
              <img src="../../assets/images/realTimePositioning/zt-zs.png" class="tab-icon" alt="" srcset="">
            <span>转速：</span>
            <div>{{ vehicle.engineRoundsObjNumber }}r/min</div>
          </div>
          <div style="width:100%;margin-bottom:0;">
              <img src="../../assets/images/realTimePositioning/zt-wz.png" class="tab-icon" alt="" srcset="">
            <span>位置：</span>
            <div style="display:flex;flex-wrap:wrap;"><AddressText
              :autoScroll="false"
              :lng="vehicle.X"
              :lat="vehicle.Y"
            ></AddressText>
            <span style="font-size: 12px;color: #999999;">（更新于{{ vehicle.PT
                ? dayjs(vehicle.PT).format("YYYY-MM-DD HH:mm:ss")
                : dayjs(vehicle.R).format("YYYY-MM-DD HH:mm:ss") }}）</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="Footer">
      <el-tabs v-if="!vehicle.isCommercialVehicles" size="mini">
        <el-tab-pane>
          <template #label>
            <label>快捷功能</label>
          </template>
          <div class="tabContent">
            <div v-if="vehicle.isVideo">
              <img src="../../assets/images/realTimePositioning/sssp.png" class="tab-icon" alt="" srcset="">
              <el-button
                type="text" style="color:#0080FF;"
                :disabled="
                  !(vehicle.mediaOnline)
                "
                @click.stop="$emit('playClick', vehicle)"
                >实时视频</el-button
              >
            </div>
            <div v-if="vehicle.isVideo">
              <img src="../../assets/images/realTimePositioning/lxhf.png" class="tab-icon" alt="" srcset="">
              <el-button style="color:#0080FF;"
                :disabled="
                  !(vehicle.mediaOnline)
                "
                type="text"
                @click.stop="onGoVod"
                >录像回放</el-button
              >
            </div>
            <div v-if="vehicle.isVideo">
              <img src="../../assets/images/realTimePositioning/dj.png" class="tab-icon" alt="" srcset="">
              <el-button style="color:#0080FF;"
                type="text"
                :disabled="
                  !(vehicle.mediaOnline)
                "
                @click.stop="onSpeakClick"
                >{{ isSpeaking ? "关闭对讲" : "对讲" }}</el-button
              >
            </div>
            <div>
              <img src="../../assets/images/realTimePositioning/gj.png" class="tab-icon" alt="" srcset="">
              <el-button style="color:#0080FF;"
                :disabled="!hasPerms('M02')"
                type="text"
                @click.stop="onGoPath('/SCDetails', 'trackDetails')"
                >轨迹</el-button
              >
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <label>指令下发</label>
          </template>
          <div class="tabContent">
            <div>
              <img src="../../assets/images/realTimePositioning/wycc.png" class="tab-icon" alt="" srcset="">
              <el-button type="text" @click.stop="onCCClick" style="color:#0080FF;"
                >我要查车</el-button
              >
            </div>
            <div>
              <img src="../../assets/images/realTimePositioning/ddxf.png" class="tab-icon" alt="" srcset="">
              <el-button type="text" @click.stop="onTextDownClick" style="color:#0080FF;"
                >调度下发</el-button
              >
            </div>
            <div>
              <img src="../../assets/images/realTimePositioning/szhc.png" class="tab-icon" alt="" srcset="">
              <el-button type="text" @click.stop="onReturnIntervalClick" style="color:#0080FF;"
                >设置回传</el-button
              >
            </div>
            <div v-if="vehicle.isVideo">
              <el-dropdown placement="bottom" @command="onZPCommandClick">
                <span style="display:flex;align-items: center;">
                  <img src="../../assets/images/realTimePositioning/zp.png" class="tab-icon" alt="" srcset="">
                  <el-button type="text" style="color:#0080FF;">抓拍</el-button>
                </span>

                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="channel in channelOptions"
                    :key="channel"
                    :command="channel"
                  >
                    <Iconfont name="icon-tongdao" :mRight="7"></Iconfont>
                    <span>通道{{ channel }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane>
          <template #label>
            <label>详细</label>
          </template>
          <div class="tabContent notFull">
            <div>
              <img src="../../assets/images/realTimePositioning/qx.png" class="tab-icon" alt="" srcset="">
              <el-button type="text" @click="onGoPath('/SCDetails')" style="color:#0080FF;"
                >详细</el-button
              >
            </div>

            <div>
              <img src="../../assets/images/realTimePositioning/yh.png" class="tab-icon" alt="" srcset="">
              <el-button type="text" @click.stop="onLinkFuel" style="color:#0080FF;">油耗</el-button>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="tabContent" v-else>
            <div v-if="vehicle.isVideo">
              <img src="../../assets/images/realTimePositioning/sssp.png" class="tab-icon" alt="" srcset="">
              <el-button
                type="text" style="color:#0080FF;"
                :disabled="
                  !(vehicle.mediaOnline)
                "
                @click.stop="$emit('playClick', vehicle)"
                >视频</el-button
              >
            </div>
            <div v-if="vehicle.isVideo">
              <img src="../../assets/images/realTimePositioning/lxhf.png" class="tab-icon" alt="" srcset="">
              <el-button style="color:#0080FF;"
                :disabled="
                  !(vehicle.mediaOnline)
                "
                type="text"
                @click.stop="onGoVod"
                >录像</el-button
              >
            </div>
            <div class="tabContent-ornament">
              <img src="../../assets/images/realTimePositioning/bj.png" class="tab-icon" alt="" srcset="">
              <el-button style="color:#0080FF;" @click="onGoPath('/SCDetails', 'alarmDetails')"
                type="text"
                >报警</el-button
              >
            </div>
            <div class="tabContent-ornament">
              <img src="../../assets/images/realTimePositioning/gj.png" class="tab-icon" alt="" srcset="">
              <el-button
                :disabled="!hasPerms('M02')" style="color:#0080FF;"
                type="text"
                @click.stop="onGoPath('/SCDetails', 'trackDetails')"
                >轨迹</el-button
              >
            </div>
            <div>
              <img src="../../assets/images/realTimePositioning/qx.png" class="tab-icon" alt="" srcset="">
              <el-button style="color:#0080FF;" @click="onGoPath('/SCDetails')"
                type="text">详情</el-button
              >
            </div>
          </div>
    </div>
    <!-- 调度下发 -->
    <CmdTextDown
      v-if="textDownObj.visible"
      v-model="textDownObj.visible"
      :vehicle="textDownObj.vehicle"
    ></CmdTextDown>
    <!-- 设置回传 -->
    <CmdReturnInterval
      v-if="returnIntervalObj.visible"
      v-model="returnIntervalObj.visible"
      :isVisible="isVisible"
      :vehicle="returnIntervalObj.vehicle"
    ></CmdReturnInterval>
    <!-- 车辆资料 -->
    <ModalVehicle
      v-if="vehicleDetailObj.visible"
      v-model="vehicleDetailObj.visible"
      :groupId="vehicleDetailObj.groupId"
      :groupName="vehicleDetailObj.groupName"
      :vehicleId="vehicleDetailObj.vehicleId"
    ></ModalVehicle>

    <ModalDetail
      v-if="carDetailObj.visible"
      v-model="carDetailObj.visible"
      :groupId="vehicleDetailObj.groupId"
      :groupName="vehicleDetailObj.groupName"
      :vehicleId="vehicleDetailObj.vehicleId"
    ></ModalDetail>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { commandDown, shootCommandDown } from "@/api/live-monitor-api";
import { getDwellTime3, getDwellTime6 } from "@/utils/formatDate";

import TextScroll from "@/components/TextScroll";
import AddressText from "@/components/AddressText";
import CmdTextDown from "@/components/CmdTextDown";
import CmdReturnInterval from "@/components/CmdReturnInterval";
import ModalVehicle from "@/components/ModalVehicleInfo";
import ModalDetail from "@/components/ModalDetailInfo";
import VehicleStateLabel from "@/components/VehicleStateLabel";

// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';
import vehiclePicture from "@/utils/vehiclePicture";
import { hasPerms } from "@/utils/auth";
import { mapMutations, mapState } from "vuex";

export default {
  name: "SCRtpWindow",
  components: {
    AddressText,
    CmdTextDown,
    CmdReturnInterval,
    ModalVehicle,
    TextScroll,
    VehicleStateLabel,
    ModalDetail,
  },
  props: {
    vehicle: {
      type: Object,
      default: null,
    },
    vehicleList: {
      type: Array,
      default: ()=> []
    },
    speakVehicle: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // 调度下发
      textDownObj: {
        // 控制显示隐藏
        visible: false,
        // 车辆对象
        vehicle: null,
      },
      // 设置回传
      returnIntervalObj: {
        visible: false,
        vehicle: null,
      },
      // 车辆详细信息
      vehicleDetailObj: {
        visible: false,
        vehicleId: 0,
        groupId: 0,
        groupName: "",
      },
      carDetailObj: {
        visible: false,
      },
      colorData: {
        0: "yellow",
        1: "NeverOnline",
        2: "Online",
        3: "blue",
        4: "Offline",
        5: "NeverOnline",
      },
      isVisible: 0,
      iconUrl: "",
      terminalName: [
        '',
        'OVP',
        'OBD',
        '有线GPS',
        '无线GPS',
        'PFT',
        'BSD',
        'AEB'
      ],
				carImgList: [
					'aerial_work_machinery1',
					'aerial_work_machinery2',
					'compaction_machinery',
					'excavating_machinery',
					'excavating_machinery1',
					'hoisting_machinery',
					'hoisting_machinery3',
					'mining_machinery',
					'piling_machinery1',
					'shovel_transport_machinery0',
					'shovel_transport_machinery1',
					'shovel_transport_machinery3',
					'industrial_vehicles1',
					'agriculture_mechanics1',
					'concrete_machinery3',
					'shovel_transport_machinery9',
          'diao_guan_ji0',
          'yi_dong_dian_zhan0',
          'han_jie_gong_zuo_zhan0',
          'zhong_pin_jia_re_che0',
          'new_energy_vehicles0',
				],
      getDwellTime6
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    getStateLabel() {
      const { vehicle } = this;
      if (vehicle) {
        // if (vehicle.isCommercialVehicles && vehicle.Z === 2) return "开工";
        // if (vehicle.isCommercialVehicles && vehicle.Z === 4) return "停工";
        // if (vehicle.Z === 0) return "怠速";
        // if (vehicle.Z === 1) return "行驶";
        // if (vehicle.Z === 2) return "作业";
        // if (vehicle.Z === 3) return "通电";
        // if (vehicle.Z === 4) return "熄火";
        // if (vehicle.Z === 5) return "离线";
        if (vehicle.Z === 0) return "停工";
        if (vehicle.Z === 1) return "开工";
        if (vehicle.Z === 5) return "离线";
        return "从未上线";
      }
      return "从未上线";
    },
    // 方向
    getDirection() {
      const { vehicle } = this;
      if (vehicle) {
        const { F } = vehicle;
        if (F > 22.5 && F <= 67.5) return "东北";
        if (F > 67.5 && F <= 112.5) return "东";
        if (F > 112.5 && F <= 157.5) return "东南";
        if (F > 157.5 && F <= 202.5) return "南";
        if (F > 202.5 && F <= 247.5) return "西南";
        if (F > 247.5 && F <= 292.5) return "西";
        if (F > 292.5 && F <= 337.5) return "西北";
        if (F <= 22.5 || F > 337.5) return "北";
      }
      return "";
    },
    gsmSinLabel() {
      const { vehicle } = this;
      if (vehicle) {
        const { gsmSin } = vehicle;
        if (gsmSin >= 1 && gsmSin < 18) return "差";
        if (gsmSin >= 18 && gsmSin < 26) return "良";
        if (gsmSin >= 26) return "优";
      }
      return "未知";
    },
    netModelLabel() {
      const { vehicle } = this;
      if (vehicle) {
        const { netModel } = vehicle;
        if (netModel === 1) return "2G";
        if (netModel === 2) return "3G";
        if (netModel === 3) return "4G";
      }
      return "";
    },
    isSpeaking() {
      const { vehicle, speakVehicle } = this;
      return speakVehicle && speakVehicle.key === vehicle.key;
    },
    // 车辆类型图标
    vehicleIconName() {
      return this.iconUrl;
      // return getProductTypeIconName(vehicle.machineryProductType, vehicle.machineryEquipmentType);
    },
    // 通道列表
    channelOptions() {
      const { vehicle } = this;
      try {
        return JSON.parse(vehicle.camreaLine);
      } catch (err) {
        //
      }
      return [];
    },
  },
  watch: {
    vehicle: {
      handler: async function (val) {
        if (!val) {
          return false;
        }
        // let url = await vehiclePicture(
        //   val.machineryProductType,
        //   val.machineryEquipmentType,
        //   val.Z
        // );
        // this.iconUrl = url;
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["setVehicleVideo", 'SetDetailsVehicleList']),
    dayjs,
    hasPerms,
    // 我要查车
    async onCCClick() {
      const { P, V, N, M, T, EP } = this.vehicle;

      try {
        const result = await commandDown({
          vehicleId: V,
          terminalNo: N,
          cmd: 7,
          commandMsg: "",
        });

        this.$message.info(result.msg || "指令已下发");
      } catch (err) {
        //
        console.error(err);
        this.$message.warning("指令下发异常");
      }
    },
    // 导航到轨迹
    onGoTrack() {
      const { vehicle } = this;
      this.$router.push({
        name: "TrackPlayback",
        params: {
          vehicle,
        },
      });
    },
    onGoVod() {
      const { vehicle } = this;
      this.setVehicleVideo(vehicle);
      this.$router.push({
        name: "VideoPlayback",
        params: {
          vehicle,
        },
      });
    },
    onSpeakClick() {
      const { vehicle } = this;
      this.$emit("speakClick", vehicle);
    },
    // 调度下发
    onTextDownClick() {
      const { textDownObj, vehicle } = this;

      textDownObj.vehicle = {
        // 设备名称
        plate: vehicle.P,
        // 终端号
        terminalNo: vehicle.N,
        // 车辆Id
        vehicleId: vehicle.V,
        // 车组Id
        groupId: vehicle.M,
      };

      textDownObj.visible = true;
    },
    // 设置回传
    onReturnIntervalClick() {
      const { returnIntervalObj, vehicle } = this;
      this.isVisible += 1;
      returnIntervalObj.vehicle = {
        // 设备名称
        plate: vehicle.P,
        // 终端号
        terminalNo: vehicle.N,
        // 车辆Id
        vehicleId: vehicle.V,
        // 车组Id
        groupId: vehicle.M,
        // 终端类型
        terminalType: vehicle.T,
      };
      returnIntervalObj.visible = true;
    },

    /**
     * 抓拍指令
     */
    async onZPCommandClick(channel) {
      const { vehicle } = this;
      const result = await shootCommandDown({
        shootId: channel,
        terminalNo: vehicle.N,
        vehicleId: vehicle.V,
      });

      this.$message.info(result.msg || "抓拍命令已下发");
    },
    /**
     * 车辆详细
     */
    onVehicleDetailClick() {
      const { vehicle, vehicleDetailObj, carDetailObj } = this;
      vehicleDetailObj.vehicleId = vehicle.V;
      vehicleDetailObj.groupId = vehicle.M;
      vehicleDetailObj.groupName = vehicle.groupName;
      carDetailObj.visible = true;
    },

    /**
     * 点击跳转油耗报表
     */
    onLinkFuel() {
      const { vehicle } = this;
      this.$router.push({
        name: "oilManagement",
        params: {
          vehicle,
        },
      });
    },
    onGoPath(path, type) {
      let vehicleList = this.vehicleList.filter(val=> val.Z < 6);
      // this.SetDetailsVehicleList(JSON.stringify(vehicleList.map(val=> val.vehicleId)))
      sessionStorage.setItem('vehicleIdList', JSON.stringify(vehicleList.map(val=> val.vehicleId)));
      this.$nextTick(()=>{
        this.$router.push({
          path: path,
          query: { vehicleIndex: vehicleList.findIndex(val=> val.vehicleId == this.vehicle.vehicleId), tabType: type }
        }); 
      })
        // params: {
        //   vehicle,
        // },
    },
    getDwellTime(e,ind) {
      return getDwellTime3(e * 1000)[ind]
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .WindowContent {
  border: none !important;
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 1px rgba(120,120,120,0.2);
  border-radius: 8px;
}
.sc-rtp-window {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 12px;
    &-right {
      display: flex;
      align-items: center;
      gap: 16px;
      ::v-deep .el-icon-close {
        color: #333;
        font-size: 12px;
        font-weight: bold;
      }
    }
    &-left {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    &-state {
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      color: #999999;
      padding: 0 8px;
      background: #EEEEEE;
      border-radius: 2px;
      border: 1px solid #E1E1E1;
    }
      &-state0 {
        color: #FCA118;
        background: #FFF5E7;
        border: 1px solid #FFE4BD;
      }
      &-state1 {
        color: #00BC0D;
        background: #E5F8E6;
        border: 1px solid #B7EDBA;
      }
      &-state3 {
        color: #3470FF;
        background: #EAF0FF;
        border: 1px solid #C5D6FF;
      }
    // &-state1 {
    //   color: #00BC0D;
    //   background: #E5F8E6;
    //   border: 1px solid #B7EDBA;
    // }
    // &-state1 {
    //   color: #FCA118;
    //   background: #FFF5E7;
    //   border: 1px solid #FFE4BD;
    // }
    &-alarm {
      flex: 1;
      display: flex;
      align-items: center;
      height: 24px;
      margin: 0 8px;
      background: rgba(255,216,216,0.3);
      border-radius: 28px 28px 28px 28px;
      image {
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
    }
  }
  .Footer {
    // height: 74px;
    display: flex;
    position: relative;
    label {
      cursor: pointer;
    }
    ::v-deep .el-tabs {
      width: 100%;
      .el-tabs__header {
        margin: 0;
      }
      .el-tabs__nav {
        display: flex;
        width: 100%;
        background: #EFF7FF;
        border-bottom: 1px solid rgba(255,255,255,0.7);;
        .el-tabs__item {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          font-size: 14px;
          color: #333333;
        }
        .el-tabs__active-bar {
          height: 4px;
          bottom: -1px;
        }
      }
    }
  }
  .ContentOuter {
    // height: calc(100% - 65px);
    // display: flex;
    // padding: 10px;
    // overflow: auto;
    &-car {
      display: flex;
      padding: 12px;
      background: #0080FF;
      ::v-deep img {
        border-radius: 4px !important;
        margin: 0 !important;
      }
      &-content {
        flex: 1;
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 8px;
        font-size: 14px;
        color: #FFFFFF;
        margin-bottom: 14px;
        &-p {
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: bold;
          font-size: 16px;
          color: #FFFFFF;
        }
      }
    }
    &-card {
      margin: -14px 12px 12px !important;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.1);
      border-radius: 4px;
      &-statistics {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        border-bottom: 1px solid #DEE3E6;
        > div {
          position: relative;
          flex: 1;
          display: flex;
          flex-direction: column;
          font-size: 12px;
          .label {
            color: #999999;
            margin-bottom: 5px;
          }
          .v {
            display: flex;
            color: #333;
          }
          span {
            font-size: 14px;
            font-weight: bold;
          }
        }
        > .bebebe {
          padding-left: 8px;
        }
        > .bebebe::before {
          content: '';
          position: absolute;
          left: 0;
          top: 6px;
          bottom: 6px;
          width: 1px;
          background: #DEE3E6;
        }
      }
      &-condition {
        display: flex;
        flex-wrap: wrap;
        padding: 12px;
        >div {
          width: 50%;
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          img {
            width: 18px;
            margin-right: 6px;
          }
          span {
            white-space: nowrap;
            font-size: 14px;
            color: #999999;
            margin-right: 2px;
          }
          div {
            font-size: 14px;
            color: #333333;
          }
        }
      }
    }
  }
  .tab-icon {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
  .Content {
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    font-size: 12px;
  }
  .Row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 7px;
  }
  .RowTitle {
    padding-right: 10px;
    font-size: 12px;
    color: #a4a4a4;
  }
  .RowContent {
    color: #000000;
    flex: 1;
    overflow: hidden;
    // span {
    //   margin: 0 5px 0 0;
    // }
  }
  // .stateSpan {
  //   width: 34px;
  //   height: 16px;
  //   background: #57d86d;
  //   border: 1px solid #3dc854;
  //   border-radius: 2px;

  //   margin-left: 10px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .tabContent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 43px;
    background: #EFF7FF;
    > div {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 43px;
    }
    &-ornament::before {
      content: '';
      position: absolute;
      right: 0;
      top: 12px;
      bottom: 12px;
      width: 1px;
      background: rgba(255,255,255,0.7);;
    }
  }

  .notFull {
    justify-content: flex-start;
    padding: 0;
    & > div {
      margin: 0 10px;
    }
  }

  .align-start {
    align-items: flex-start;
  }
  .justify-between {
    display: flex;
    justify-content: space-between;
  }
  .color-red {
    color: red;
  }
  .imgCls {
    height: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e1e1e1;
  }
  .mL10 {
    margin-left: 10px;
  }

  ::v-deep.el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    .el-tabs__content {
      position: relative;
      padding: 0;
      flex: 1;
    }
    .el-tab-pane {
      height: 100%;
    }
    .el-tabs__nav {
      width: 100%;
      display: flex;
    }
    .el-tabs__item {
      flex: 1;
    }
  }
}
</style>
