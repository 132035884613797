<template>
  <div class="RtpMap">
    <BSJMap ref="mapRef" :zoom="zoom" :center="centerLng" @loaded="onMapLoad">
      <!-- 聚合点 -->
      <MapClusterer
        ref="clusterObj"
        :points="clusterPoints"
        :minClusterSize="minClusterSize" :gridSize="gridSize"
        pointKey="key"
        :markerOffset="markerOffset"
        @markerClick="onMarkerClick"
      >
        <template #marker="{point}">
          <!-- <Iconfont
            :name="getProductTypeIconName(point.data.machineryProductType, point.data.machineryEquipmentType)"
            :size="32"
            :rotate="point.data.F || 0"
          ></Iconfont>-->
          <!-- <Iconfont name="icon-a-zu517" :size="32" :rotate="point.data.F || 0"></Iconfont> -->
          <Iconfont :size="48" :height="52" :style="{filter: `grayscale(${point.data.Z != 5 ? '0%' : '100%'})`}" :name="point.data.iconUrl"></Iconfont>
        </template>
      </MapClusterer>

      <!-- 展示地图信息窗体 -->
      <InfoWindow
        ref="windowObj"
        v-model="infoVisible"
        :lngLat="windowPoint"
        :offset="[-4, -15]"
        :width="390"
        :height="0"
        autoMove
      >
        <RtpWindow
          :vehicle="windowVehicle"
          :speakVehicle="speakVehicle" :vehicleList="vehicleList"
          @close="infoVisible=false"
          @playClick="$emit('playClick', $event)"
          @speakClick="$emit('speakClick',$event)"
        ></RtpWindow>
      </InfoWindow>
    </BSJMap>
      <div class="ranging" @click="hadnleRuler">
        距离测量
      </div>
  </div>
</template>

<script>
import BSJMap, { InfoWindow, MapClusterer } from '@/components/AMap';
import RtpWindow from './SCRtpWindow.vue';

import GPS from '@/utils/address';
import { mapState } from "vuex";

// import { getProductTypeIconName } from '@/utils/getProductTypeIconName';

import { amapGetIp } from "@/api/weather"
import vehiclePicture from '@/utils/vehiclePicture';
import { getDeviceInfo } from "@/api/live-monitor-api";


export default {
  name: 'RtpMap',
  components: {
    BSJMap,
    MapClusterer,
    InfoWindow,
    RtpWindow,
  },
  model: {
    prop: 'currentVehicle'
  },
  props: {
    /**
     * 聚合的最小数量。默认值为2，即小于2个点则不能成为一个聚合
     */
    minClusterSize: {
      type: Number,
      default: 10
    },
    zoom: {
      type: Number,
      default: 10
    },
    gridSize: {
      type: Number,
      default: 1
    },
    currentVehicle: {
      type: Object,
      default: null,
    },
    vehicleList: {
      type: Array,
      default() {
        return [];
      }
    },
    speakVehicle: {
      type: Object,
      default: null,
    }
  },
  data() {
    // 地图实例
    this.mapObj = null;
    return {
      markerOffset: [0, 0],
      infoVisible: false,
      centerLng: null,
      ruleIsOn:false,
      windowVehicle: {},
    };
  },
  computed: {
    // 聚合点
    clusterPoints() {
      const { vehicleList } = this;
      return vehicleList.map((obj,indd) => {
        const point = GPS.delta(obj.Y, obj.X);
        return {
          key: obj.key || indd,
          lng: point.lon,
          lat: point.lat,

          // lng: 116.40121,
          // lat: 39.907795,

          data: obj
        };
      });
    },
    windowPoint() {
      // this.infoVisible = true;
      // return [116.40121, 39.907795];
      const { currentVehicle } = this;
      if (!currentVehicle) {
        this.infoVisible = false;
        return [];
      }
      const point = GPS.delta(currentVehicle.Y, currentVehicle.X);

      return [point.lon, point.lat];
    },
    ...mapState(['realIP'])
  },
  watch: {
    clusterPoints(val) {
      if (val.length <= 0) {
        this.infoVisible = false;
      };
      // if (this.currentVehicle) {
      //   this.infoVisible = true;
      //   this.windowVehicle = JSON.parse(JSON.stringify(val));
      //   this.getWindowVehicle();
      //   this.panToCenter();
      // }
    },
    currentVehicle: {
      async handler(val, oldV) {
        const { clusterPoints } = this;
        //  || clusterPoints.length <= 0
        if (!val) {
          this.infoVisible = false;
          return;
        }
        this.infoVisible = true;
          this.getWindowVehicle();
          if (!oldV || val.vehicleId != oldV.vehicleId ) {
            this.panToCenter();
          }
      }
    }
  },
  beforeMount() {

  },
  created() {
    amapGetIp({ key: '60f85fa7d1c353b0a2ffb92083e365f0',ip: this.realIP }).then(res=>{
    // amapGetIp({ key: '60f85fa7d1c353b0a2ffb92083e365f0',ip: '113.71.214.232' }).then(res=>{
      if (res.rectangle && res.rectangle.length > 0) {
        let rectangle = res.rectangle.split(';');
        let rectangle1 = rectangle[0].split(',');
        let rectangle2 = rectangle[1].split(',');
        let Lng = parseFloat(rectangle1[0]) + (parseFloat(rectangle1[0]) - parseFloat(rectangle2[0]));
        let Lat = parseFloat(rectangle1[1]) + (parseFloat(rectangle1[1]) - parseFloat(rectangle2[1]));
        this.centerLng = [ Lng, Lat ]
      }
    })
  },
  mounted() {
  },
  beforeDestroy() {

  },
  methods: {
    // getProductTypeIconName,
    panToCenter() {
      const { windowPoint } = this;
      const { mapRef } = this.$refs;
      if (mapRef) {
        mapRef.panTo(windowPoint);
      }
    },
    async getWindowVehicle() {
        let res = await getDeviceInfo({
          vehicleId: this.currentVehicle.vehicleId
        });
        if (res.flag == 1 && this.currentVehicle.vehicleId == res.obj.data[0].vehicleId) {
          let vehicle = res.obj.data[0];
          vehicle.isVideo = vehicle.isSupportMedia;
          this.windowVehicle = vehicle;
          this.$forceUpdate();
        }
    },
    async setFitView() {
      await this.$nextTick();

      const { clusterObj } = this.$refs;
      if (clusterObj) {
        clusterObj.setFitView();
      }
    },
    async onMarkerClick(obj) {
      this.infoVisible = true;
      this.$emit('input', obj.data);
    },
    // 地图加载完成
    onMapLoad(map) {
      this.mapObj = map;
    },
    // 测距工具
    hadnleRuler() {

      if(this.ruleIsOn) return;

      let startMarkerOptions= {
        icon: new AMap.Icon({
        size: new AMap.Size(19, 31),//图标大小
        imageSize:new AMap.Size(19, 31),
        image: "https://webapi.amap.com/theme/v1.3/markers/b/start.png"
        })
      };
      let endMarkerOptions = {
        icon: new AMap.Icon({
          size: new AMap.Size(19, 31),//图标大小
          imageSize:new AMap.Size(19, 31),
          image: "https://webapi.amap.com/theme/v1.3/markers/b/end.png"
        }),
        offset: new AMap.Pixel(-9, -31)
      };
      let midMarkerOptions = {
        icon: new AMap.Icon({
          size: new AMap.Size(19, 31),//图标大小
          imageSize:new AMap.Size(19, 31),
          image: "https://webapi.amap.com/theme/v1.3/markers/b/mid.png"
        }),
        offset: new AMap.Pixel(-9, -31)
      };
      let lineOptions = {
        strokeStyle: "solid",
        strokeColor: "#FF33FF",
        strokeOpacity: 1,
        strokeWeight: 2
      };
      var rulerOptions = {
        startMarkerOptions: startMarkerOptions,
        midMarkerOptions:midMarkerOptions,
        endMarkerOptions: endMarkerOptions,
        lineOptions: lineOptions
      };
      const that = this;
      this.mapObj.plugin(["AMap.RangingTool"],function(){

        const ruler = new AMap.RangingTool(that.mapObj, rulerOptions);
        ruler.turnOn();

        that.ruleIsOn=true;

        AMap.event.addListener(ruler,"end",function(e){
          ruler.turnOff();

          that.ruleIsOn=false;

        });

      });
    },
    vehicleIconName(val) {
      return vehiclePicture(val.machineryProductType, val.machineryEquipmentType, val.Z == 1 ? 2 : val.Z , true);
    }
  }
}
</script>

<style lang="scss" scoped>
.RtpMap {
  position: relative;
  width: 100%;
  height: 100%;
}
.ranging {
  position: absolute;
  right: 12px;
  color: #333;
  top: 100px;
  padding: 2px 1px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}
::v-deep .WindowContent {
  border: none !important;
  box-shadow: 0px 0px 20px 1px rgba(120,120,120,0.2);
  border-radius: 8px 8px 8px 8px;
}
</style>